import { useCallback, useEffect, useState } from "react";
import NextImage from "next/image";
import { useInView } from "react-intersection-observer";
import UpArrow from "assets/imgs/up-arrow-full.svg";
import { useResponsiveContext } from "context/ResponsiveContext";
import Button from "elements/Button";
import Grid from "elements/Grid";
import TestimonialsHero from "public/testimonials-hero.svg";
import GoogleReviewIcon from "public/google-review-icon.png";
import Reviews from "./reviews.json";
import GeorgiaReviews from "./georgiaReviews.json";
import Link from "next/link";
import Head from "next/head";
import { StatesWithStatePage } from "utils/types";
import ButtonV2 from "elements/ButtonV2";

const REVIEW_TO_LOAD = 3;

const PAGE_TITLE = "Testimonials";
const PAGE_DESCRIPTION =
	"No need to take our word for it! Here’s just a few of our 5 star reviews.";

interface Testimonials {
	name: string;
	msg: string;
	link: string;
	location?: string;
}

export const Star = (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M21.2981 10.4054L17.1346 14.6645L18.1179 20.68C18.1606 20.9431 18.0577 21.2089 17.8518 21.366C17.7355 21.4551 17.5972 21.5 17.4588 21.5C17.3525 21.5 17.2456 21.4733 17.148 21.4193L12 18.5792L6.85265 21.4186C6.62807 21.5435 6.35469 21.5231 6.14883 21.3653C5.94296 21.2082 5.84003 20.9424 5.8828 20.6793L6.86602 14.6638L2.70189 10.4054C2.52008 10.2188 2.45391 9.93962 2.53278 9.6864C2.61165 9.43318 2.82086 9.2473 3.07285 9.20872L8.82711 8.33192L11.4004 2.85931C11.6257 2.38023 12.3743 2.38023 12.5996 2.85931L15.1729 8.33192L20.9272 9.20872C21.1791 9.2473 21.3883 9.43248 21.4672 9.6864C21.5461 9.94032 21.4799 10.2181 21.2981 10.4054Z"
			fill="#FFC421"
		/>
	</svg>
);

export const StarPartial = (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M21.2981 10.4054L17.1346 14.6645L18.1179 20.68C18.1606 20.9431 18.0577 21.2089 17.8518 21.366C17.7355 21.4551 17.5972 21.5 17.4588 21.5C17.3525 21.5 17.2456 21.4733 17.148 21.4193L12 18.5792L6.85265 21.4186C6.62807 21.5435 6.35469 21.5231 6.14883 21.3653C5.94296 21.2082 5.84003 20.9424 5.8828 20.6793L6.86602 14.6638L2.70189 10.4054C2.52008 10.2188 2.45391 9.93962 2.53278 9.6864C2.61165 9.43318 2.82086 9.2473 3.07285 9.20872L8.82711 8.33192L11.4004 2.85931C11.6257 2.38023 12.3743 2.38023 12.5996 2.85931L15.1729 8.33192L20.9272 9.20872C21.1791 9.2473 21.3883 9.43248 21.4672 9.6864C21.5461 9.94032 21.4799 10.2181 21.2981 10.4054Z"
			fill="#D4DEE3"
		/>
		<mask
			id="mask0_1044_5213"
			style={{ maskType: "alpha" }}
			maskUnits="userSpaceOnUse"
			x="2"
			y="2"
			width="20"
			height="20">
			<path
				d="M21.2981 10.4054L17.1346 14.6645L18.1179 20.68C18.1606 20.9431 18.0577 21.2089 17.8518 21.366C17.7355 21.4551 17.5972 21.5 17.4588 21.5C17.3525 21.5 17.2456 21.4733 17.148 21.4193L12 18.5792L6.85265 21.4186C6.62807 21.5435 6.35469 21.5231 6.14883 21.3653C5.94296 21.2082 5.84003 20.9424 5.8828 20.6793L6.86602 14.6638L2.70189 10.4054C2.52008 10.2188 2.45391 9.93962 2.53278 9.6864C2.61165 9.43318 2.82086 9.2473 3.07285 9.20872L8.82711 8.33192L11.4004 2.85931C11.6257 2.38023 12.3743 2.38023 12.5996 2.85931L15.1729 8.33192L20.9272 9.20872C21.1791 9.2473 21.3883 9.43248 21.4672 9.6864C21.5461 9.94032 21.4799 10.2181 21.2981 10.4054Z"
				fill="white"
			/>
		</mask>
		<g mask="url(#mask0_1044_5213)">
			<rect x="2" y="2" width="14" height="20" fill="#FFC421" />
		</g>
	</svg>
);

const ReviewCard = ({
	msg,
	name,
	location,
}: {
	msg: string | undefined;
	name: string | undefined;
	location?: string | undefined;
}) => {
	return (
		<div className="testimonials-endless-card testimonials-fade-in">
			<div className="stars">
				{Star}
				{Star}
				{Star}
				{Star}
				{Star}
			</div>
			<p className="testimonials-endless-card-review">{msg}</p>
			<div className="testimonials-endless-card-review-from-row">
				<label className="testimonials-endless-card-name">
					{name}
					{location && (
						<span className="testimonials-endless-card-location">
							{location}
						</span>
					)}
				</label>
				<NextImage
					className="google-review-icon"
					src={GoogleReviewIcon}
					alt="Google Icon"
					style={{
						maxWidth: "100%",
						height: "auto",
					}}
				/>
			</div>
		</div>
	);
};

export const useGoogleReviewRows = (
	initNumberOfReviews: number = 3,
	target?: StatesWithStatePage
) => {
	const [currentReviews, setCurrentReviews] = useState(initNumberOfReviews);
	const { isDesktop, isMobile } = useResponsiveContext();
	const [allCardsLoaded, setAllCardsLoaded] = useState(false);
	const [googleReviewsRow1, setGoogleReviewsRow1] = useState<Testimonials[]>();
	const [googleReviewsRow2, setGoogleReviewsRow2] = useState<Testimonials[]>();
	const [googleReviewsRow3, setGoogleReviewsRow3] = useState<Testimonials[]>();

	let reviews: Testimonials[];

	if (target === "GA") {
		reviews = GeorgiaReviews.georgiaGoogleReviews;
	} else {
		reviews = Reviews.googleReviews;
	}

	const splitReviews = () => {
		if (isDesktop) {
			setAllCardsLoaded(false);
			const splitNum = Math.round(reviews.length / 3);
			setGoogleReviewsRow1(reviews.slice(0, splitNum));
			setGoogleReviewsRow2(reviews.slice(splitNum, splitNum * 2));
			setGoogleReviewsRow3(reviews.slice(splitNum * 2, reviews.length));
			return;
		} else if (isMobile) {
			setAllCardsLoaded(false);
			setGoogleReviewsRow1([...reviews]);
			setGoogleReviewsRow2([]);
			setGoogleReviewsRow3([]);
			return;
		} else {
			setAllCardsLoaded(false);
			const splitNum = Math.round(reviews.length / 2);
			setGoogleReviewsRow1(reviews.slice(0, splitNum));
			setGoogleReviewsRow2(reviews.slice(splitNum, reviews.length));
			setGoogleReviewsRow3([]);
		}
	};

	useEffect(() => {
		splitReviews();
	}, [isDesktop, isMobile]);

	return {
		currentReviews,
		setCurrentReviews,
		allCardsLoaded,
		setAllCardsLoaded,
		googleReviewRows: {
			googleReviewsRow1,
			googleReviewsRow2,
			googleReviewsRow3,
		},
	};
};

export const GoogleReviewsColumns = ({
	currentReviews,
	googleReviewsRow1,
	googleReviewsRow2,
	googleReviewsRow3,
}: {
	currentReviews: number;
	googleReviewsRow1: Testimonials[] | undefined;
	googleReviewsRow2: Testimonials[] | undefined;
	googleReviewsRow3: Testimonials[] | undefined;
}) => {
	const { isDesktop, isMobile } = useResponsiveContext();
	return (
		<Grid>
			<div className="testimonials-page-endless-section-wrapper-one">
				{googleReviewsRow1?.map(
					({ name, msg, link, location }, index) =>
						index < currentReviews && (
							<ReviewCard
								name={name}
								msg={msg}
								location={location}
								key={"cardsRow1" + index}
							/>
						)
				)}
			</div>
			{(!isMobile || isDesktop) && (
				<div className="testimonials-page-endless-section-wrapper-two">
					{googleReviewsRow2?.map(
						({ name, msg, link, location }, index) =>
							index < currentReviews && (
								<ReviewCard
									name={name}
									msg={msg}
									location={location}
									key={"cardsRow2" + index}
								/>
							)
					)}
				</div>
			)}
			{isDesktop && (
				<div className="testimonials-page-endless-section-wrapper-three">
					{googleReviewsRow3?.map(
						({ name, msg, link, location }, index) =>
							index < currentReviews && (
								<ReviewCard
									name={name}
									msg={msg}
									location={location}
									key={"cardsRow3" + index}
								/>
							)
					)}
				</div>
			)}
		</Grid>
	);
};

export default function Testimonials() {
	const [y, setY] = useState(0);

	const { isDesktop, isMobile } = useResponsiveContext();
	const { ref, inView } = useInView();

	const {
		currentReviews,
		setCurrentReviews,
		allCardsLoaded,
		setAllCardsLoaded,
		googleReviewRows,
	} = useGoogleReviewRows(3);

	const { googleReviewsRow1, googleReviewsRow2, googleReviewsRow3 } =
		googleReviewRows;

	const loadMoreReviews = () => {
		if (isDesktop) {
			if (
				currentReviews + REVIEW_TO_LOAD < (googleReviewsRow3?.length ?? 0) ||
				googleReviewsRow3?.length === 0
			) {
				setCurrentReviews(currentReviews + REVIEW_TO_LOAD);
			} else if (currentReviews + REVIEW_TO_LOAD >= googleReviewsRow3!.length) {
				setCurrentReviews(googleReviewsRow3!.length);
				setAllCardsLoaded(true);
			}
			return;
		} else if (isMobile) {
			if (currentReviews + REVIEW_TO_LOAD < googleReviewsRow1!.length) {
				setCurrentReviews(currentReviews + REVIEW_TO_LOAD);
			} else if (currentReviews + REVIEW_TO_LOAD >= googleReviewsRow1!.length) {
				setCurrentReviews(googleReviewsRow1!.length);
				setAllCardsLoaded(true);
			}
			return;
		} else {
			if (currentReviews + REVIEW_TO_LOAD < googleReviewsRow2!.length) {
				setCurrentReviews(currentReviews + REVIEW_TO_LOAD);
			} else if (currentReviews + REVIEW_TO_LOAD >= googleReviewsRow2!.length) {
				setCurrentReviews(googleReviewsRow2!.length);
				setAllCardsLoaded(true);
			}
		}
	};

	useEffect(() => {
		inView && loadMoreReviews();
	}, [inView]);

	const handleScroll = useCallback(
		e => {
			const window = e.currentTarget;
			setY(window.scrollY);
		},
		[y]
	);

	useEffect(() => {
		setY(window.scrollY);
		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [handleScroll]);

	return (
		<div id="testimonials-page">
			<Head>
				<title>{PAGE_TITLE}</title>
				<meta property="title" content={PAGE_TITLE} key="title" />
				<meta property="og:title" content={PAGE_TITLE} key="og:title" />
				<meta
					property="twitter:title"
					content={PAGE_TITLE}
					key="twitter:title"
				/>
				<meta name="description" content={PAGE_DESCRIPTION} key="description" />
				<meta
					property="og:description"
					content={PAGE_DESCRIPTION}
					key="og:description"
				/>
			</Head>
			<div id="testimonials-page-hero-section">
				<Grid>
					<TestimonialsHero
						id="testimonials-page-hero-img"
						height="100%"
						width="100%"
					/>
					<div id="testimonials-page-title-container">
						<h1 id="testimonials-page-title">Thousands of happy customers</h1>
						<p id="testimonials-page-body">
							No need to take our word for it! Here’s just a few of our
							5&nbsp;star&nbsp;reviews.
						</p>
					</div>
				</Grid>
			</div>
			<div id="testimonials-page-endless-section">
				<GoogleReviewsColumns
					currentReviews={currentReviews}
					{...googleReviewRows}
				/>
				{!allCardsLoaded && <div ref={ref}></div>}
			</div>
			<div
				id="testimonials-fixed-scroll-div"
				className={`${y > 500 && "testimonials-fixed-scroll-div-show"}
          `}>
				<Link passHref href="#testimonials-page">
					<ButtonV2 id="testimonials-fixed-scroll-btn">
						Scroll to Top <UpArrow />
					</ButtonV2>
				</Link>
			</div>
		</div>
	);
}
